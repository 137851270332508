var $ = require('jquery');
var imagesLoaded = require('imagesloaded');
var TweenLite = require('gsap');
var scrollTo = require('../../node_modules/gsap/src/uncompressed/plugins/ScrollToPlugin.js');
var slick = require('../../node_modules/slick-carousel/slick/slick.min.js');

window.app = {
	W: $(window).width(),
	interval: [],

	init: function() {
		var _this = this;

		this.setupEvents();

		$('.slider').slick({
			autoplay: true,
			speed: 300,
			autoplaySpeed: 4500,
			fade: true,
			dots: true,
			accessibility: false,
			pauseOnHover: false,
			pauseOnDotsHover: true,
			arrows : false
		});

		this.preload();

		var iconHome = '<?xml version="1.0" encoding="iso-8859-1"?> <!-- Generator: Adobe Illustrator 19.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --> <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"viewBox="0 0 486.196 486.196" style="enable-background:new 0 0 486.196 486.196;" xml:space="preserve"> <g> <path d="M481.708,220.456l-228.8-204.6c-0.4-0.4-0.8-0.7-1.3-1c-5-4.8-13-5-18.3-0.3l-228.8,204.6c-5.6,5-6,13.5-1.1,19.1 c2.7,3,6.4,4.5,10.1,4.5c3.2,0,6.4-1.1,9-3.4l41.2-36.9v7.2v106.8v124.6c0,18.7,15.2,34,34,34c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0h70.6 c17.6,0,31.9-14.3,31.9-31.9v-121.3c0-2.7,2.2-4.9,4.9-4.9h72.9c2.7,0,4.9,2.2,4.9,4.9v121.3c0,17.6,14.3,31.9,31.9,31.9h72.2 c19,0,34-18.7,34-42.6v-111.2v-34v-83.5l41.2,36.9c2.6,2.3,5.8,3.4,9,3.4c3.7,0,7.4-1.5,10.1-4.5 C487.708,233.956,487.208,225.456,481.708,220.456z M395.508,287.156v34v111.1c0,9.7-4.8,15.6-7,15.6h-72.2c-2.7,0-4.9-2.2-4.9-4.9 v-121.1c0-17.6-14.3-31.9-31.9-31.9h-72.9c-17.6,0-31.9,14.3-31.9,31.9v121.3c0,2.7-2.2,4.9-4.9,4.9h-70.6c-0.3,0-0.5,0-0.8,0 s-0.5,0-0.8,0c-3.8,0-7-3.1-7-7v-124.7v-106.8v-31.3l151.8-135.6l153.1,136.9L395.508,287.156L395.508,287.156z"/> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>';
		$('.sidenav > ul > li').first().append(iconHome);
	},

	setupEvents: function() {
		var _this = this;

		$('a').click(function(event) {
			event.preventDefault();

			var _link = $(this).attr('href');

			if ($(this).attr('target') == '_blank') {
				window.location = _link;
			}
			
			if (_link.substr(0, 4) == 'http' || _link.substr(0, 1) == '/') {
				$('body').addClass('leaving');
				app.scrollToTop();

				$('.header-nav-trigger').removeClass('active');

				setTimeout(function() {
					window.location = _link;
				}, 400);
			} else if (_link.substr(0, 1) !== '#') {
				window.location = _link;
			}
		});

		$('.asLink').click(function(event) {
			event.preventDefault();

			var _link = $(this).data('url');

			if ($(this).attr('target') == '_blank') {
				window.location = _link;
			}
			
			if (_link.substr(0, 4) == 'http' || _link.substr(0, 1) == '/') {
				$('body').addClass('leaving');
				app.scrollToTop();

				$('.header-nav-trigger').removeClass('active');

				setTimeout(function() {
					window.location = _link;
				}, 400);
			} else if (_link.substr(0, 1) !== '#') {
				window.location = _link;
			}
		});

		$('.header-nav-trigger a').click(function(event) {
			event.preventDefault();
			_this.toggleMobileNav();
		});

		$(window).on('resize', function(event) {
			event.preventDefault();
			_this.onResize();
		});
	},

	onResize: function() {
		app.W = $(window).width();
	},

	scrollToTop: function() {
		if ($(window).scrollTop() > 0) {
			this.scrollTo({
				pos: 0, 
				speed: 0.4
			});
		}
	},

	scrollTo: function(params) {

		if (params.pos == undefined) {params.pos == 0};
		if (params.speed == undefined) {params.speed == 0.3};

		TweenLite.to( window, params.speed, {
			scrollTo: {
				y: params.pos
			},
			ease: Cubic.linear, 
			onComplete: function(){
				if (params.after) {
					params.after();
				}
			}
		});
	},

	toggleMobileNav: function() {
		var $body = $('body'),
			$nav = $('.header-nav-trigger');

		if ($body.hasClass('mobileNavActive')) {
			$body.removeClass('mobileNavActive');
			$nav.removeClass('active');
		} else {
			$body.addClass('mobileNavActive');
			$nav.addClass('active');
		}
	},

	preload: function(images) {

		var $body = $('body'),
			_this = this;

		if (images) {
			$.each(images, function(index, val) {
				$body.find('.footer').append('<img class="preloading" src=" ' + val +' ">');
			});
		}

		imagesLoaded( 'body', { background: true }, function() {
			$body.find('.preloading').each(function(index, el) {
				$(this).remove();
			});

			setTimeout(function() {
				$body.removeClass('loading');
			}, 400);
		});
	}
};

$(document).ready(function() {
	window.app.init();
});